import axios from 'axios'
// import Parse from "parse";

export default {
  getCurrentPosition: ({ commit }) => {
    return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          res => {
            let pos = {
              lat: res.coords.latitude,
              lng: res.coords.longitude
            };
            resolve(pos);
          },
          err => {
            reject(`${err.message}`);
          }
        );
      } else {
        reject("Location service unavailable.");
      }
    })
      .then(pos => {
        commit("UPDATE_CURRENT_POSITION", pos);
      })
      .catch(err => {
        console.log(err);
      });
  },

/*   fetchGoodies: async ({ commit }) => {
    Parse.initialize("youth_card", "BxKgYB44P9ko9o1b6Qfc6XHPIUxVWZRMJAJqkTw5"); // Application ID and JavaScript KEY
    Parse.serverURL = "https://parse.pixelpoint.biz/parse";

    let goodie = Parse.Object.extend("goodie");
    var query = new Parse.Query(goodie);

    let goodies = [];

    query.each(object => {
        goodies.push(object.toJSON());
    }).then(() => {
      commit("FETCH_GOODIES", goodies);
    })
  }, */

  fetchGoodiesAXIOS: async ({ commit }) => {
  axios
      .get('https://www.kaerntnerjugendkarte.at/goodies.php?type=familienkarte')
      .then((goodies) => {
        commit("FETCH_GOODIES", goodies.data);
      })
      .catch(error => console.log('Failed to fetch goodies ',error))
  },
  fetchMapRouteAXIOS: async (pos1, pos2) => {
    axios
      .get('https://api.mapbox.com/directions/v5/mapbox/driving/'+pos1.lng+','+pos1.lat+';'+pos2.lng+','+pos2.lat+'?access_token=pk.eyJ1Ijoic2luZ2VyOTA2IiwiYSI6ImNrZGlnd2Q5czA0dXcyeHEzcDY0ZHVjZnEifQ.3-R36hvTz6yK3hi2SjoeuQ')
      //.get('https://api.mapbox.com/directions/v5/mapbox/driving/'+pos1.lng+','+pos1.lat+';'+pos2.lng+','+pos2.lat+'?access_token=pk.eyJ1IjoidGhvbWFzLW90dGkiLCJhIjoiY2thcDZudnhpMDE1djJ4dDUxM2NvYzN1MCJ9.PI4dGy0Hu3xD-6c7OSE_3A') // Developer Token
      .then((response) => {
        console.log("Fetched route: ", response);
      })
      .catch(error => console.log('Failed to fetch route ',error))
  }
};
