export default {
  currentPosition: {
    lat: '', // default Klagenfurt
    lng: '' // default Klagenfurt
  },
  card: {},
  goodies: [],
  categoryFilter: '',
  locationFilter: '',
  goodieSearchText: '',
  nearbyFilterActive: false,
  highlightedGoodie: null,
  highlightedLocation: null,
  acceptedMapboxTerms: false
};
