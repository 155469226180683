<template>
  <div class="">
    <div class="sticky top-0"><BackNav :viewName="viewName" /></div>

    <div class="flex flex-col">
      <div id="img-container" class="flex justify-center h-32 pb-8 bg-xYellow">
        <img
          class="object-contain w-full mt-4"
          src="../assets/imgs/FK20_Logo_RGB_weiss.png"
          alt="Familienkarte"
        />
      </div>
    </div>

    <div class="flex justify-center">
      <div class="mt-4 px-4 w-full max-w-2xl">
        <h3 class=" text-xl">Mapbox</h3>
        <p class="mt-4">
          Diese Website verwendet für die Darstellung interaktiver Karten Mapbox, einen Dienst der Mapbox Inc. (1714 14th Street NW Washington, DC 20009-4309 United States). Durch den Aufruf dieser Website werden Informationen über die Benutzung dieser Website inklusive IP-Adresse an Mapbox USA übermittelt. Durch den direkten Verbindungsaufbau Ihres Browsers mit Mapbox werden Karteninhalte von Mapbox direkt an Ihren Browser übertragen und von dort in die Website eingebunden. Somit haben wir keinerlei Einfluss auf die damit erhobenen Daten. Sollten Sie keinerlei Datenverarbeitung durch Mapbox wünschen, deaktivieren Sie in Ihren Browsereinstellungen JavaScript. Dies hat jedoch zur Folge, dass die Karteneinstellungen für Sie nicht nutzbar sind. Weitere Informationen zur Erhebung und Verarbeitung Ihrer Daten finden Sie hier: <a href="https://www.mapbox.com/privacy/" rel="nofollow" class=" text-blue-500">https://www.mapbox.com/privacy/</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import BackNav from "@/components/BackNav.vue";
export default {
  components: {
    BackNav
  },
  data() {
    return {
      viewName: "Datenschutzerklärung"
    };
  }
};
</script>

<style></style>
