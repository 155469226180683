<template>
  <div class="mx-4 mb-4" v-on:click="setSelectedGoodie">
    <router-link :to="{ name: 'singleGoodie', params: {id: goodie.nid} }">
      <div class="flex flex-col items-center justify-center bg-white p-4 shadow rounded-lg">
        <div class="inline-flex shadow-lg border border-gray-200 overflow-hidden h-24 w-40 p-1">
					<img class="object-contain w-full" :src="goodie.logo" :alt="goodie.title + ' Logo'" />
				</div>
        <h2 class="mt-6 font-bold text-xl text-center">{{goodie.title}}</h2>
        <p v-html="goodie.vorteile_kurz" />
        <div class="mt-6">
          <span class="bg-categoryBlue rounded-full mx-2 py-1 px-6 text-center text-white text-xs">{{goodie.kategorie}}</span>
          <span class="bg-categoryBlue rounded-full mx-2 py-1 px-6 text-center text-white text-xs">{{goodie.typ}}</span>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "goodieCard",
  data() {
    return {
    };
  },
  computed: {},
  props: {
    goodie: {
      type: Object,
      default: null
    }
  },
  methods: {
    setSelectedGoodie() {
      this.$store.commit("SET_SELECTED_GOODIE", this.goodie);
      this.$store.commit("SET_SELECTED_LOCATION", null);
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
};
</script>

<style></style>
