<template>
  <div class="absolute bg-navGray h-full w-64 z-50 flex flex-col">
    <div class="px-6 mb-6 self-center">
      <img src="../assets/imgs/FK20_Logo_RGB_weiss.png" alt="Kärnten Card Logo" class="h-16 sm:h-auto mt-4" />
    </div>

    <hr />
    <div class="flex p-2 hover:bg-gray-800 items-center" v-on:click="$emit('homeClicked')">
      <img src="../assets/icons/icon_0@2x.png" alt="icon" width="30" height="30" />
      <p class="ml-3 text-white">Home</p>
    </div>

    <hr />
    <router-link to="goodie-club" class="">
      <div class="flex p-2 hover:bg-gray-800 items-center">
        <img src="../assets/icons/icon_1@2x.png" alt="icon" width="30" height="30" />
        <p class="ml-3 text-white">GoodieClub</p>
      </div>
    </router-link>

    <hr />
    <router-link to="rund-um-die-familie" class="">
      <div class="flex p-2 hover:bg-gray-800 items-center">
        <img src="../assets/icons/icon_2@2x.png" alt="icon" width="30" height="30" />
        <p class="ml-3 text-white">Rund um die Familie</p>
      </div>
    </router-link>
    <!-- <a href="https://www.kaerntnerfamilienkarte.at/rund-um-die-familie" class="">
      <div class="flex p-2 hover:bg-gray-800 items-center">
        <img
          src="../assets/icons/icon_2@2x.png"
          alt="icon"
          width="30"
          height="30"
        />
        <p class="ml-3 text-white">Rund um die Familie</p>
      </div>
    </a> -->

    <hr />
    <router-link to="news" class="">
      <div class="flex p-2 hover:bg-gray-800 items-center">
        <img src="../assets/icons/icon_3@2x.png" alt="icon" width="30" height="30" />
        <p class="ml-3 text-white">News</p>
      </div>
    </router-link>

    <hr />
    <router-link to="newsletter" class="">
      <div class="flex p-2 hover:bg-gray-800 items-center">
        <img src="../assets/icons/icon_nl@2x.png" alt="icon" width="30" height="30" />
        <p class="ml-3 text-white">Newsletter</p>
      </div>
    </router-link>

    <hr />
    <router-link to="jugendkarte" class="">
      <div class="flex p-2 hover:bg-gray-800 items-center">
        <img src="../assets/icons/icon_4@2x.png" alt="icon" width="30" height="30" />
        <p class="ml-3 text-white">Jugendkarte</p>
      </div>
    </router-link>

    <!-- <hr />
    <router-link to="meine-karte" class="">
      <div class="flex p-2 hover:bg-gray-800 items-center">
        <img
          src="../assets/icons/icon_card@2x.png"
          alt="icon"
          width="30"
          height="30"
        />
        <p class="ml-3 text-white">Meine Karte</p>
      </div>
    </router-link> -->

    <hr />
    <router-link to="impressum" class="">
      <div class="flex p-2 hover:bg-gray-800 items-center">
        <img src="../assets/icons/icon_5@2x.png" alt="icon" width="30" height="30" />
        <p class="ml-3 text-white">Impressum & Datenschutz</p>
      </div>
    </router-link>
    <hr />

    <div class="p-6 self-center">
      <img src="../assets/imgs/ktn_logo@2x.png" alt="Kärnten Card Logo" class="w-32 sm:w-auto" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidebarMenu',
  components: {},
  props: {},
  data() {
    return {}
  },
  methods: {},
}
</script>

<style></style>
