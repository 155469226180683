import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker"; // Register Service worker
import router from "./router/router";
import store from "./store/store";
import axios from "axios";
import VueAxios from "vue-axios";
import "./assets/styles/main.css"; // For tailwind
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons"; // ToDo: import only the icons needed instead of the entire library.
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Notifications from "vue-notification";
import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";

library.add(fas, fab, far); // ToDo: import only the icons needed instead of the entire library.

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(VueAxios, axios);
Vue.use(Notifications);
Vue.use(VueSidebarMenu);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
