import * as geolib from "geolib";

export default {
  getCategoryFilter: state => {
    return state.categoryFilter;
  },
  getLocationFilter: state => {
    return state.locationFilter;
  },
  getGoodieSearchText: state => {
    return state.goodieSearchText;
  },
  getNearbyFilterActive: state => {
    return state.nearbyFilterActive;
  },
  getUserPosition: state => {
    return state.currentPosition;
  },
  getFilteredGoodies: state => {
    let categorieFilter = state.categoryFilter;
    let locationFilter = state.locationFilter;
    let goodieSearchText = state.goodieSearchText;
    let nearbyFilterActive = state.nearbyFilterActive;
    let filteredGoodies = state.goodies;
    let userPosition = state.currentPosition;

    // First Filter: nearbyFilter
    if (nearbyFilterActive === true) {
      filteredGoodies = filteredGoodies.filter(item => {
        if (item.locations.length > 0) {
          for (var i = 0; i < item.locations.length; i++) {
            if (item.locations[i].lat && item.locations[i].lon) {
              console.log('Checking distance...')
              return geolib.isPointWithinRadius(
                { latitude: userPosition.lat, longitude: userPosition.lng },
                { latitude: item.locations[i].lat, longitude: item.locations[i].lon },
                10000 // 10km
              );
            }
          }
        } else {
          return false;
        }

        /* if (item.geolocation) {
          return geolib.isPointWithinRadius(
            { latitude: userPosition.lat, longitude: userPosition.lng },
            { latitude: item.geolocation.lat, longitude: item.geolocation.lng },
            10000 // 10km
          );
        } else {
          return false;
        } */
      });
    }

    // Second Filter: categorieFilter
    if (categorieFilter !== "") {
      filteredGoodies = filteredGoodies.filter(item => {
        return item.kategorie === categorieFilter;
      });
    }

    // Third Filter: locationFilter
    if (locationFilter !== "") {
      filteredGoodies = filteredGoodies.filter(item => {
        for (var i = 0; i < item.bezirke.length; i++) {
          if (item.bezirke[i] === locationFilter) {
            console.log('Element ' + item.bezirke[i] + ' equals Filter ' + locationFilter)
            return true;
          }
        }
      });
    }

    // Fourth Filter: goodieSearchText
    if (goodieSearchText !== "") {
      filteredGoodies = filteredGoodies.filter(item => {
        if (
          item.title &&
          item.title.toLowerCase().includes(goodieSearchText.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    }

    filteredGoodies = JSON.parse(JSON.stringify(filteredGoodies));
    console.log(filteredGoodies.length + " Filtered Goodies: ", filteredGoodies);
    return filteredGoodies;
  },
  getGoodieByNID: (state) => (nid) => {
		return state.goodies.find(goodie => goodie.nid === nid)
  },
  getSelectedGoodie: state => {
    return state.highlightedGoodie;
  },
  getSelectedLocation: state => {
    return state.highlightedLocation;
  }
};
