export default {
  UPDATE_CURRENT_POSITION: (state, pos) => {
    state.currentPosition = pos;
    console.log(
      "User position updated [lat, lng]: ",
      state.currentPosition.lat,
      state.currentPosition.lng
    );
  },
  REMOVE_CARD_FROM_STORE: state => {
    state.card = {};
  },
  ADD_CARD_TO_STORE: (state, card) => {
    state.card = card;
  },
  FETCH_GOODIES: (state, goodies) => {
    state.goodies = goodies;
    console.log(state.goodies.length + " Goodies fetched from server: ", state.goodies);
  },
  SET_CATEGORY_FILTER: (state, categoryFilter) => {
    if (categoryFilter === 'Kategorie') {
      state.categoryFilter = '';
    } else {
      state.categoryFilter = categoryFilter;
    }
  },
  SET_LOCATION_FILTER: (state, locationFilter) => {
    if (locationFilter === 'Bezirk') {
      state.locationFilter = '';
    } else {
      state.locationFilter = locationFilter;
    }
  },
  SET_GOODIE_SEARCH_TEXT: (state, goodieSearchText) => {
    state.goodieSearchText = goodieSearchText;
  },
  SET_NEARBY_FILTER: (state, nearbyFilterActive) => {
    state.nearbyFilterActive = nearbyFilterActive;
  },
  SET_SELECTED_GOODIE: (state, selectedGoodie) => {
    state.highlightedGoodie = selectedGoodie;
    console.log('SET_SELECTED_GOODIE: ', selectedGoodie)
  },
  SET_SELECTED_LOCATION: (state, selectedLocation) => {
    state.highlightedLocation = selectedLocation;
    console.log('SET_SELECTED_Location: ', selectedLocation)
  }
};
