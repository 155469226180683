<template>
  <div>
    <div class="py-3 flex flex-col sm:flex-row flex-wrap justify-center">
      <div id="KategorieFilter" class="relative inline-block text-left mx-5 my-2">
        <div>
          <span class="rounded-md shadow-sm">
            <button type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500" @click="toggleCategorySelection()">
              {{categoryFilter}}
              <svg class="-mr-1 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </span>
        </div>
      </div>

      <div id="LocationFilter" class="relative inline-block text-left mx-5 my-2">
        <div>
          <span class="rounded-md shadow-sm">
            <button type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500" @click="toggleLocationSelection()">
              {{locationFilter}}
              <svg class="-mr-1 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </span>
        </div>
      </div>

      <div id="InDerNähe" class="relative inline-block text-left mx-5 my-2">
        <div>
          <span class="rounded-md shadow-sm">
            <button type="button" v-if="userPosition.lat !== '' && userPosition.lng !== ''" @click="toggleNearbyFilter()" :class="{'inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150':!this.nearbyFilterActive, 'inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-green-300 text-sm leading-5 font-medium text-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150':this.nearbyFilterActive}">
              In der Nähe
            </button>
            <button type="button" v-if="userPosition.lat === '' && userPosition.lng === ''" @click="showGPSerror()" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-red-700 text-sm leading-5 font-medium text-gray-700 transition ease-in-out duration-150">
              In der Nähe
            </button>
          </span>
        </div>
      </div>

      <div id="SearchField" class="relative inline-block inline-flex mx-5 my-2">
        <input type="search" class="appearance-none bg-white shadow-sm rounded-md border border-gray-300 pl-3 pr-8 outline-none w-full" :placeholder="goodieSearchText" v-model="searchtext" @keyup="setSearchText">
        <font-awesome-icon class="absolute right-1 self-center" icon="search" size="xs" :style="{ color: 'gray' }" />
      </div>

      <div id="ResetAllFilters" v-show="this.categoryFilter !== 'Kategorie' || this.locationFilter !== 'Bezirk' || this.nearbyFilterActive === true || this.searchtext !== '' || this.goodieSearchText !== ''" class="relative inline-block text-left mx-5 my-2">
        <div>
          <span class="rounded-md shadow-sm">
            <button type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-xYellow text-sm leading-5 font-medium text-gray-700 hover:text-gray-500" @click="resetAllFilters()">
              Alle Filter zurücksetzen
            </button>
          </span>
        </div>
      </div>

      <div id="Map" class="relative inline-block text-left mx-5 my-2 flex justify-center">
        <div>
          <span class="rounded-md shadow-sm">
            <button type="button" class="inline-flex justify-center rounded-full border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150" @click="showOnMap">
              <font-awesome-icon icon="map-marked-alt" size="lg" :style="{ color: 'black' }" />
            </button>
          </span>
        </div>
      </div>
    </div>

    <!-- ----- Controls -->
    <notifications class="" group="gps-notification" position="bottom center" />
    <!-- +++++ Filters -->

    <div class="flex justify-center">
      <div id="category-selection" class="py-4 px-5 w-full sm:w-64" v-show="this.showCategorySelection">
        <ul class="">
          <li class="flex justify-center">
            <button class="w-full py-1 bg-white hover:bg-xYellow font-bold rounded border border-white mb-1" v-on:click="setCategoryFilter('Kategorie')">Filter zurücksetzen</button>
          </li>
          <li class="flex justify-center">
            <button class="w-full py-1 bg-white font-light hover:bg-xYellow rounded border border-white mb-1" v-on:click="setCategoryFilter('sport')">Sport &amp; Spiel</button>
          </li>
          <li class="flex justify-center">
            <button class="w-full py-1 bg-white font-light hover:bg-xYellow rounded border border-white mb-1" v-on:click="setCategoryFilter('handel')">Handel &amp; Dienstleistung</button>
          </li>
          <li class="flex justify-center">
            <button class="w-full py-1 bg-white font-light hover:bg-xYellow rounded border border-white mb-1" v-on:click="setCategoryFilter('kultur')">Kultur &amp; Events</button>
          </li>
          <li class="flex justify-center">
            <button class="w-full py-1 bg-white font-light hover:bg-xYellow rounded border border-white mb-1" v-on:click="setCategoryFilter('gastronomie')">Gastronomie &amp; Tourismus</button>
          </li>
        </ul>
      </div>
    </div>

    <div class="flex justify-center">
      <div id="location-selection" class="py-4 px-5 w-full sm:w-64" v-show="this.showLocationSelection">
        <ul class="">
          <li class="flex justify-center">
            <button class="w-full py-1 bg-white hover:bg-xYellow font-bold rounded border border-white mb-1" v-on:click="setLocationFilter('Bezirk')">Filter zurücksetzen</button>
          </li>
          <li class="flex justify-center">
            <button class="w-full py-1 bg-white font-light hover:bg-xYellow rounded border border-white mb-1" v-on:click="setLocationFilter('KL')">Klagenfurt am Wörthersee</button>
          </li>
          <li class="flex justify-center">
            <button class="w-full py-1 bg-white font-light hover:bg-xYellow rounded border border-white mb-1" v-on:click="setLocationFilter('VK')">Völkermarkt</button>
          </li>
          <li class="flex justify-center">
            <button class="w-full py-1 bg-white font-light hover:bg-xYellow rounded border border-white mb-1" v-on:click="setLocationFilter('WO')">Wolfsberg</button>
          </li>
          <li class="flex justify-center">
            <button class="w-full py-1 bg-white font-light hover:bg-xYellow rounded border border-white mb-1" v-on:click="setLocationFilter('SV')">St. Veit an der Glan</button>
          </li>
          <li class="flex justify-center">
            <button class="w-full py-1 bg-white font-light hover:bg-xYellow rounded border border-white mb-1" v-on:click="setLocationFilter('VI')">Villach</button>
          </li>
          <li class="flex justify-center">
            <button class="w-full py-1 bg-white font-light hover:bg-xYellow rounded border border-white mb-1" v-on:click="setLocationFilter('SP')">Spittal an der Drau</button>
          </li>
          <li class="flex justify-center">
            <button class="w-full py-1 bg-white font-light hover:bg-xYellow rounded border border-white mb-1" v-on:click="setLocationFilter('HE')">Hermagor</button>
          </li>
          <li class="flex justify-center">
            <button class="w-full py-1 bg-white font-light hover:bg-xYellow rounded border border-white mb-1" v-on:click="setLocationFilter('FE')">Feldkirchen</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      showCategorySelection: false,
      showLocationSelection: false,
      searchtext: ''
    };
  },
  computed: {
    filterActive() {
      return this.showCategorySelection || this.showLocationSelection;
    },
    categoryFilter() {
      switch (this.$store.getters.getCategoryFilter) {
        case '':
          return 'Kategorie'

        case 'sport':
          return 'Sport'

        case 'handel':
          return 'Handel'

        case 'kultur':
          return 'Kultur'
        
        case 'gastronomie':
          return 'Gastronomie'

        default:
          return 'Kategorie'
      }
    },
    locationFilter() {
      switch (this.$store.getters.getLocationFilter) {
        case '':
          return 'Bezirk'

        case 'KL':
          return 'Klagenfurt'

        case 'VK':
          return 'Völkermarkt'

        case 'WO':
          return 'Wolfsberg'
        
        case 'SV':
          return 'St. Veit'

        case 'VI':
          return 'Villach'

        case 'SP':
          return 'Spittal'
        
        case 'HE':
          return 'Hermagor'
        
        case 'FE':
          return 'Feldkirchen'

        default:
          return 'Bezirk'
      }
    },
    goodieSearchText() {
      return this.$store.getters.getGoodieSearchText;
    },
    nearbyFilterActive(){
      return this.$store.getters.getNearbyFilterActive;
    },
    userPosition() {
      return this.$store.getters.getUserPosition;
    }
  },
  methods: {
    toggleCategorySelection() {
      if (this.showLocationSelection) {
        this.toggleLocationSelection()
      }
      this.showCategorySelection = !this.showCategorySelection;
    },
    toggleLocationSelection() {
      if (this.showCategorySelection) {
        this.toggleCategorySelection()
      }
      this.showLocationSelection = !this.showLocationSelection;
    },
    setCategoryFilter(filter) {
      this.$store.commit("SET_CATEGORY_FILTER", filter);
      this.showCategorySelection = false;
      this.showLocationSelection = false;
    },
    setLocationFilter(filter) {
      this.$store.commit("SET_LOCATION_FILTER", filter);
      this.showCategorySelection = false;
      this.showLocationSelection = false;
    },
    setSearchText () {
      this.$store.commit("SET_GOODIE_SEARCH_TEXT", this.searchtext);
    },
    showOnMap() {
      this.$router.push({ name: "map"});
    },
    toggleNearbyFilter() {
      if (this.nearbyFilterActive === true) {
        this.$store.commit("SET_NEARBY_FILTER", false);
      } else {
        this.$store.commit("SET_NEARBY_FILTER", true);
      }
      console.log('Nearby Filter active?', this.nearbyFilterActive)
    },
    showGPSerror () {
      this.$notify({
        group: "gps-notification",
        type: "error",
        text: "Wir konnten Ihren Standort leider nicht bestimmen!"
      });
    },
    resetAllFilters () {
      this.setCategoryFilter('');
      this.setLocationFilter('');
      this.searchtext = '';
      this.setSearchText('');
      this.$store.commit("SET_NEARBY_FILTER", false);
      this.showCategorySelection = false;
      this.showLocationSelection = false;
    }
  }
};
</script>

<style></style>
