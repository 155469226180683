<template>
  <div class="flex justify-center bg-navGray flex-none">
    <div
      class="bg-navGray w-full content-center flex items-center h-12 py-3 px-2"
    >
      <button class="absolute ml-2" @click="navigateBack">
        <font-awesome-icon
          icon="angle-left"
          size="lg"
          :style="{ color: 'white' }"
        /><span class="ml-2 text-white">Zurück</span>
      </button>
      <p class="flex-1 text-center text-white">{{ viewName }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "backNav",
  components: {},
  props: {
    viewName: {
      type: String,
      default: ""
    },
    backwardsRoute: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  methods: {
    navigateBack() {
      //console.log(this.backwardsRoute)
      if (this.backwardsRoute === "") {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: this.backwardsRoute });
      }
    }
  }
};
</script>

<style></style>
