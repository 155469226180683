<template>
  <div class="flex flex-col items-stretch h-full min-h-screen">
    <BackNav :viewName="viewName" :backwardsRoute="'home'" />
    <ExternalSite
      :webLink="'https://www.kaerntnerfamilienkarte.at/rund-um-die-familie'"
    />
  </div>
</template>

<script>
import BackNav from "@/components/BackNav.vue";
import ExternalSite from "@/components/ExternalSite.vue";
export default {
  name: "Familienkarte",
  components: {
    BackNav,
    ExternalSite
  },
  data() {
    return {
      viewName: "Rund um die Familie"
    };
  }
};
</script>

<style></style>
