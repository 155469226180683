<template>
  <div id="app">
    <router-view />
    <A2HSiOS v-if="showA2HSiOS" v-on:denyA2HS="hideA2HS"/>
    <A2HSChrome v-if="showA2HSChrome" v-on:acceptA2HS="triggerA2HSprompt" v-on:denyA2HS="hideA2HS"/>
    <div class=" bg-xYellow rounded-lg border border-white shadow-lg p-6 z-50 fixed bottom-4 left-1/2 transform -translate-x-1/2" v-if="updateExists">
      <p class=" text-white font-bold text-2xl">Update verfügbar!</p>
      <button class=" py-4 px-10 text-green-700 text-xl border border-green-700 rounded-lg mt-4" @click="refreshApp">Aktualisieren</button>
    </div>
  </div>
</template>

<script>
import A2HSiOS from './components/A2HSiOS.vue'
import A2HSChrome from './components/A2HSChrome.vue'
import platform from 'platform-detect'

export default {
  components: {
    A2HSiOS,
    A2HSChrome
  },
  data() {
    return {
      showA2HSiOS: false,
      showA2HSChrome: false,
      installprompt: null,
      registration: null,
      updateExists: false,
    };
  },
  methods: {
    triggerA2HSprompt(){
      this.installprompt.prompt()
      this.showA2HSChrome = false
      this.showA2HSiOS = false
    },
    hideA2HS(){
      this.showA2HSChrome = false
      this.showA2HSiOS = false
    },
    updateAvailable(event) {
      console.log('update available event: ', event)
      this.registration = event.detail
      this.updateExists = true
    },
    refreshApp(){
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) return
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
  },
  created() {
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    })
  },
  mounted() {
    this.$store.dispatch("getCurrentPosition");
    this.$store.dispatch("fetchGoodiesAXIOS");

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.installprompt = e
      console.log('beforeinstallprompt event detected', e)
      if (platform.pwa) {
        console.log(`I'm in PWA mode`)
      } else {
        console.log(`I'm in website mode`)
        this.showA2HSChrome = true
      }
    })

    if (!platform.pwa) {
      if (platform.ios) {
        if (platform.safari) {
          this.showA2HSiOS = true
        }
      }
    }
  }
};
</script>

<style></style>
