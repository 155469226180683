<template>
  <div class="flex-grow relative">
    <iframe class="w-full absolute top-0 left-0 h-full" :src="webLink" :title="webLink" />
  </div>
</template>

<script>
export default {
  props: {
    webLink: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped></style>
