<template>
  <div class="h-full w-screen flex flex-col">
    <div
      id="overlay"
      class="absolute w-full h-full bg-black bg-opacity-50 z-30"
      v-show="!hideSideNav"
      v-on:click="toggleSideNav"
    ></div>

    <div id="top-nav" class="flex justify-center bg-navGray h-12 px-5 z-20">
      <div class="flex flex-row content-center justify-between w-full">
        <button class="" v-on:click="toggleSideNav">
          <img src="../assets/icons/icon_menu@2x.png" alt="icon" width="32" height="32"/>
        </button>
        <button class="" @click="$router.push({ name: 'goodieClub' })">
          <img src="../assets/icons/icon_1@2x.png" alt="icon" width="32" height="32"/>
        </button>
      </div>
    </div>

    <SidebarMenu v-show="!hideSideNav" v-on:homeClicked="toggleSideNav" />

    <div class="absolute w-full h-full flex content-center justify-center bg-xYellow overflow-hidden">
      <img class="absolute top-20 left-1/2 transform -translate-x-2/3 w-56 md:w-1/4 z-10 max-w-xs" src="../assets/imgs/FK20_Logo_RGB_weiss.png" alt="Logo" />
      <img class="absolute h-full object-cover" src="../assets/imgs/main_mask@2x.png" alt="Family Image" />
    </div>

    <div class="absolute flex bg-white w-full h-24 justify-center z-20 bottom-0">
      <img class="w-full max-w-2xl object-contain" src="../assets/imgs/bottom_logos@2x.png" alt="Bottom Logo" />
    </div>
  </div>
</template>

<script>
import SidebarMenu from "@/components/SidebarMenu.vue";
export default {
  name: "Home",
  components: {
    SidebarMenu
  },
  data() {
    return {
      hideSideNav: true
    };
  },
  methods: {
    toggleSideNav() {
      this.hideSideNav = !this.hideSideNav;
    }
  }
};
</script>

<style scoped>
</style>
