<template>
  <div class="">
    <div class="fixed sticky top-0 z-30"><BackNav :viewName="viewName" :backwardsRoute="backRoute" /></div>
    <div v-if="acceptedMapboxTerms || tempAccess">
      <MglMap class="fixed w-full h-screen"
        :accessToken="accessToken"
        :mapStyle="mapStyle"
        :center="mapcenter"
        :minZoom="8"
        :maxZoom="16"
        @load="onMapLoaded"
      >
        <MglMarker v-if="showUserMarker" :coordinates="[userPosition.lng, userPosition.lat]" color="blue" />
        <div v-for="goodie in filteredGoodies" :key="goodie.nid">
          <MglMarker v-for="location in goodie.locations" :key="location.id" :coordinates="[location.lon, location.lat]" color="green" @click="markerClicked(location.id)" />
        </div>
        <MglMarker v-if="selectedLocation" :coordinates="[getGoodieByLocationId(selectedLocation).locations[parseInt(selectedLocation.split('_')[1])].lon, getGoodieByLocationId(selectedLocation).locations[parseInt(selectedLocation.split('_')[1])].lat]" color="red" />
      </MglMap>
      <div class="absolute bottom-0">
        <button class="bg-xYellow rounded-full h-10 w-10 m-5 outline-none" @click="showMyPosition">
          <font-awesome-icon
            icon="crosshairs"
            size="lg"
            :style="{ color: 'black' }"
          />
        </button>
      </div>
    </div>
    <div class="px-4" v-else>
      <div class=" bg-xYellow max-w-xl mx-auto mt-16 p-8 flex justify-center items-center flex-col rounded-lg shadow-lg">
        <p class=" text-navGray text-center font-bold">Mit dem Laden des Kartenmaterials akzeptieren Sie die <button class=" text-green-700 underline" @click="$router.push({ path: '/datenschutzerklaerung' })">Datenschutzerklärung</button> von Mapbox.</p>
        <button class=" p-4 border border-green-700 my-4 text-green-700 font-bold" @click="setMapboxTerms()">Karte laden</button>
        <div class=" flex justify-center items-center">
          <input type="checkbox" name="" id="acceptForever" v-model="acceptForever">
          <label for="acceptForever" class="text-navGray ml-4">Mapbox immer automatisch laden</label>
        </div>
      </div>
    </div>
    <div class="absolute bottom-0 w-full flex justify-center">
      <GoodieCard v-if="selectedLocation" :selectedLocation="selectedLocation" v-on:deselectHighlightedMarker="deselectMarker"/>
    </div>
    <notifications class="" group="gps-notification" position="bottom center" />

  </div>
</template>

<script>
import BackNav from "@/components/BackNav.vue";
import GoodieCard from "@/components/GoodieMapCard.vue";
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker } from "vue-mapbox";

export default {
  components: {
    BackNav,
    MglMap,
    MglMarker,
    GoodieCard
  },
  data() {
    return {
      viewName: "Navigation",
      accessToken: "pk.eyJ1Ijoic2luZ2VyOTA2IiwiYSI6ImNrZGlnd2Q5czA0dXcyeHEzcDY0ZHVjZnEifQ.3-R36hvTz6yK3hi2SjoeuQ",
      // accessToken: "pk.eyJ1IjoidGhvbWFzLW90dGkiLCJhIjoiY2thcDZudnhpMDE1djJ4dDUxM2NvYzN1MCJ9.PI4dGy0Hu3xD-6c7OSE_3A", // Developer token
      mapStyle: "mapbox://styles/mapbox/streets-v11",
      showUserMarker: false,
      mapcenter: [14.312225, 46.636460],
      selectedLocation: null,
      acceptForever: true,
      tempAccess: false
    };
  },
  props: {},
  computed: {
    acceptedMapboxTerms() {
      return this.$store.state.acceptedMapboxTerms
    },
    userPosition() {
      return this.$store.getters.getUserPosition;
    },
    filteredGoodies() {
      return this.$store.getters.getFilteredGoodies
      /* return this.$store.getters.getFilteredGoodies.filter(goodie => {
        if (goodie.geolocation !== null) {
          return true
        } else {
          return false
        }
      }) */
    },
    backRoute() {
      if (this.$store.getters.getSelectedGoodie) {
        return ""
      } else {
        return "goodieClub"
      }
    }
  },
  methods: {
    setMapboxTerms() {
      if (this.acceptForever) {
        // accept mapbox forever
        this.$store.state.acceptedMapboxTerms = true
      } else {
        // temp. accept mapbox
        this.tempAccess = true
      }
      
    },
    onMapLoaded(event) {
      console.log("Map loaded event detected", event);
      this.map = event.map;
      this.mapAction = event.component.actions;

    },
    showMyPosition() {
      this.$store.dispatch("getCurrentPosition");
      if (this.userPosition.lat === '' || this.userPosition.lng === '') {
        this.$notify({
          group: "gps-notification",
          type: "error",
          text: "Wir konnten Ihren Standort leider nicht bestimmen!"
        });
      } else {
        this.showUserMarker = true
        console.log('Flying to', this.userPosition)
        this.mapAction.flyTo({
          center: [this.userPosition.lng, this.userPosition.lat],
          zoom: 16,
          speed: 1
        }).then( result => console.log('Returned object from flyTo(): ',result))
      }
    },
    markerClicked(locationId) {
      this.selectedLocation = locationId
      console.log('Marker selected: ' + this.selectedLocation)
    },
    getGoodieByLocationId(locationId) {
      return this.$store.getters.getGoodieByNID(parseInt(locationId.split('_')[0]))
    },
    deselectMarker() {
      this.selectedLocation = null
    }
  },
  created() {
    this.map = null;
    this.mapAction = null;
  },
  mounted() {
    console.log(this.acceptedMapboxTermsTemporary);
    console.log(this.acceptedMapboxTermsForever);
    this.mapbox = Mapbox;
    console.log(this.$store.getters.getFilteredGoodies.length + ' Filtered goodies store: ', this.$store.getters.getFilteredGoodies)
    console.log('Loaded selected location from store: ',this.$store.getters.getSelectedLocation)
    if (this.$store.getters.getSelectedLocation) {
      this.selectedLocation = this.$store.getters.getSelectedLocation
    }
  }
};
</script>

<style></style>
