<template>
  <div class="" v-if="goodie">
    <div class="sticky top-0"><BackNav :viewName="viewName" :backwardsRoute="'goodieClub'"/></div>

    <div class="flex flex-col">
      <div id="img-container" class="flex justify-center h-32 my-6">
        <img
          class="object-contain w-full"
          :src="goodie.logo"
          :alt="goodie.title"
        />
      </div>
    </div>

    <div class="flex justify-center">
      <div class="mt-6 px-4 w-full max-w-2xl">
        <div>
          <p class=" text-xl">{{goodie.title}}</p>
          <p class="mt-8 mb-3 font-bold">Vorteile:</p>
          <p v-html="goodie.vorteile"></p>
        </div>

        <p class="mt-12 mb-3 font-bold">Kontakt:</p>
        <div class="" v-for="location in goodie.locations" :key="location.street">
          <div class="bg-gray-200 px-2 py-2 mb-3 rounded rounded-lg shadow">
            <div>
              <p class=" font-bold">{{location.name}}</p>
              <p>{{location.street}}</p>
              <p>{{location.country}} {{location.zip}} {{location.city}}</p>
            </div>
            <p class="mt-3">Telefon: <a class="text-blue-500" :href="location.phone">{{location.phone}}</a></p>
            <p class="">E-Mail: <a class="text-blue-500" :href="location.email">{{location.email}}</a></p>
            <p class="">Website: <a class="text-blue-500" :href="location.website">{{location.website}}</a></p>
            <div class="flex justify-center mt-10 mb-4" v-show="location.lat && location.lon">
              <button class="bg-xYellow px-16 py-4 rounded shadow-lg" v-on:click="showOnMap(location.id)">
                Auf der Karte anzeigen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackNav from "@/components/BackNav.vue";
export default {
  name: "Goodie",
  components: {
    BackNav
  },
  data() {
    return {
      viewName: "Goodie",
      // goodie: this.$route.params.goodie
    };
  },
  computed: {
    goodie() {
      return this.$store.getters.getSelectedGoodie;
    }
  },
  methods: {
    showOnMap(locationId) {
      this.$store.commit("SET_SELECTED_LOCATION", locationId);
      this.$router.push({ name: "map"});
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
};
</script>

<style></style>
