<template>
  <div class="w-full fixed bottom-1 z-50 flex justify-center">
      <div class=" bg-xYellow w-64 rounded border border-white p-2 shadow-md relative">
        <button class="bg-white rounded-full border border-black text-black w-6 h-6 absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 items-center justify-center text-center" v-on:click="$emit('denyA2HS')"><p>X</p></button>
        <p class=" font-bold text-navGray">App installieren</p>
        <p class=" text-navGray mt-2 ">Möchten Sie diese Webapp installieren?</p>
        <div class="flex justify-around mb-2 mt-4">
          <button class="rounded-full border px-10 py-2 bg-green-600 text-white" v-on:click="$emit('acceptA2HS')">Ok</button>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "A2HSiOS",
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {}
};
</script>

<style></style>
