<template>
  <div class="flex flex-col items-stretch h-full min-h-screen">
    <BackNav :viewName="viewName" :backwardsRoute="'home'" />
    <ExternalSite :webLink="'https://www.kaerntnerfamilienkarte.at/news'" />
  </div>
</template>

<script>
import BackNav from "@/components/BackNav.vue";
import ExternalSite from "@/components/ExternalSite.vue";
export default {
  name: "News",
  components: {
    BackNav,
    ExternalSite
  },
  data() {
    return {
      viewName: "News"
    };
  }
};
</script>

<style></style>
