<template>
  <div class="">
    <div class="sticky top-0"><BackNav :viewName="viewName" /></div>

    <div id="header" class="bg-xYellow flex content-center justify-center py-3 sm:py-12">
      <p class="text-white text-4xl">Impressum</p>
    </div>

    <!-- <div class="flex flex-col">
      <div id="img-container" class="flex justify-center h-32 pb-8 bg-xYellow">
        <img
          class="object-contain w-full"
          src="../assets/imgs/logo_white@2x.png"
          alt="Familienkarte"
        />
      </div>
    </div> -->

    <div class="flex flex-col justify-center">
      <div class="mt-16 px-4 w-full max-w-2xl mx-auto">
        <p class="mb-2 font-bold">Medieninhaber:</p>
        <p>
          Amt der Kärntner Landesregierung<br />
          Abt. 13 - Gesellschaft und Integration<br />
          Hasnerstraße 8<br />
          9020 Klagenfurt am Wörthersee<br />
        </p>
        <p class="mt-3">
          Tel: +43 50 536 -33071
        </p>
        <p class="mt-3">
          E-Mail:
          <a class="text-blue-500" href="mailto:familienkarte@ktn.gv.at"
            >familienkarte@ktn.gv.at</a
          >
        </p>
      </div>

      <div class="mt-16 px-4 w-full max-w-2xl mx-auto">
        <p class="mb-2 font-bold">Web-Design und technische Umsetzung:</p>
        <p>
          pixelpoint multimedia werbe gmbh<br />
          Rosentaler Str. 150<br />
          A-9020 Klagenfurt am Wörthersee<br />
        </p>
        <p class="mt-3">
          E-Mail:
          <a class="text-blue-500" href="mailto:office@pixelpoint.at"
            >office@pixelpoint.at</a
          >
        </p>
        <p class="mt-3">
          Website:
          <a class="text-blue-500" href="https://www.pixelpoint.at/"
            >www.pixelpoint.at</a
          >
        </p>
      </div>


      <div class="mt-16 px-4 w-full max-w-2xl mx-auto">
        <!-- https://dse-generator.pixelpoint.at/?contact-full=Amt+der+K%C3%A4rntner+Landesregierung%2C+Abt.+13+-+Gesellschaft+und+Integration%2C+%2C+Hasnerstra%C3%9Fe+8%2C+9020+Klagenfurt+am+W%C3%B6rthersee&contact-email=familienkarte%40ktn.gv.at&topic-10=1&topic-23=1&topic-50=1&topic-50-90=1&topic-60=1&topic-70=1&topic-80=1&topic-90=1&topic-100=1&topic-110=1&topic-110-10=1&topic-110-20=1&topic-110-30=1&heading_level_start=1&submit=DSE+jetzt+generieren%21 -->
        <h1 class="mb-2 font-bold text-xl">Datenschutzerklärung</h1>
        <p>
          Diese Website wird von der Amt der K&auml;rntner Landesregierung, Abt. 13 - Gesellschaft und Integration, , Hasnerstra&szlig;e 8, 9020 Klagenfurt am W&ouml;rthersee betrieben. Diese Mitteilung beschreibt, wie Ihre personenbezogenen Daten im Zusammenhang mit dieser Website verarbeitet werden.
        </p>
        <h2 class="mb-2 font-bold mt-8 text-lg">1. Welche Daten wir über Sie verarbeiten</h2>
        <p>
          Im Zuge Ihres Besuches dieser Website werden wir folgende Informationen erheben: Das Datum und die Uhrzeit des Aufrufs einer Seite auf unserer Website, Ihre IP-Adresse, Name und Version Ihres Web-Browsers, die Website (URL), die Sie vor dem Aufruf dieser Website besucht haben, bestimmte Cookies (siehe nachfolgende Punkte) und jene personenbezogenen Daten, die Sie selbst durch Ausfüllen des Kontaktformulars (Angabe von Name und E-Mail Adresse) zur Verfügung stellen.
        </p>
        <h2 class="mb-2 font-bold mt-8 text-lg">2. Cookies</h2>
        <p>
          Unsere Website verwendet Cookies. Cookies sind kleine Textdateien, die ein Webserver zu Ihrem Browser sendet wenn Sie die Website besuchen.
        </p>
        <p>
          Wir verwenden ausschließlich für den Betrieb der Website und deren Funktionen notwendige, sogenannte essenzielle, Cookies. Dies kann folgende Funktionen umfassen: Warenkorb eines Onlineshops, Cookie zur Überprüfung des Loginstatus und andere ähnliche Cookies. Diese Cookies werden und können nicht zur Verfolgung (Tracking) des Besuchers verwendet werden. Weiters werden die meisten dieser Cookies nach dem Schließen des Browsers automatisch gelöscht (Session-Cookies).
        </p>
        <h2 class="mb-2 font-bold mt-8 text-lg">3. Verwendete Tools</h2>
        <h3 class="mb-2 font-bold mt-8">3.1. Mapbox</h3>
        <p>Mapbox setzt unter Umständen selbst Cookies ein.</p>
        <button v-if="acceptedMapboxTerms" @click="removeMapbox()" class=" border border-green-700 text-green-700 py-2 px-6 mt-4">Berechtigung entziehen</button>
        <p class="mt-4">
          Diese Website verwendet für die Darstellung interaktiver Karten Mapbox, einen Dienst der Mapbox Inc. (1714 14th Street NW Washington, DC 20009-4309 United States). Durch den Aufruf dieser Website werden Informationen über die Benutzung dieser Website inklusive IP-Adresse an Mapbox USA übermittelt. Durch den direkten Verbindungsaufbau Ihres Browsers mit Mapbox werden Karteninhalte von Mapbox direkt an Ihren Browser übertragen und von dort in die Website eingebunden. Somit haben wir keinerlei Einfluss auf die damit erhobenen Daten. Sollten Sie keinerlei Datenverarbeitung durch Mapbox wünschen, deaktivieren Sie in Ihren Browsereinstellungen JavaScript. Dies hat jedoch zur Folge, dass die Karteneinstellungen für Sie nicht nutzbar sind. Weitere Informationen zur Erhebung und Verarbeitung Ihrer Daten finden Sie hier: <a href="https://www.mapbox.com/privacy/" rel="nofollow" class=" text-blue-500">https://www.mapbox.com/privacy/</a>.
        </p>
        <h2 class="mb-2 font-bold mt-8 text-lg">4. Zwecke der Datenverarbeitung</h2>
        <p>Wir werden Ihre personenbezogenen Daten zu folgenden Zwecken verarbeiten:</p>
        <ul>
          <li>um Ihnen diese Website zur Verfügung zu stellen und um diese Website weiter zu verbessern und zu entwickeln;</li>
          <li>um Nutzungsstatistiken erstellen zu können;</li>
          <li>um Angriffe auf unsere Website erkennen, verhindern und untersuchen zu können;</li>
          <li>um auf Ihre Anfrage antworten zu können und/oder zur Durchführung vorvertraglicher Maßnahmen;</li>
          <li>um den Werbeauftritt auf unserer Website zu verbessern;</li>
          <li>um den technischen Support der Nutzer zu verbessern;</li>
          <li>um auf kosteneffiziente Weise eine leicht zu verwendende Website-Zugriffsstatistik zu erstellen;</li>
          <li>um Bewerbungen entgegennehmen zu können</li>
        </ul>
        <h2 class="mb-2 font-bold mt-8 text-lg">5. Rechtsgrundlage der Verarbeitung</h2>
        <p>
          Die Verarbeitung Ihrer personenbezogenen Daten (siehe Punkt 1) erfolgt ausschließlich auf der Rechtsgrundlage
        </p>
        <ul>
          <li>der Erfüllung des Vertrages mit Ihnen und/oder zur Durchführung vorvertraglicher Maßnahmen (Art 6 Abs 1 lit b DSGVO);<br/>
            und/oder
          </li>
          <li>unseres überwiegenden berechtigten Interesses, die im Punkt 4 genannten Zwecke zu erreichen (Art 6 Abs 1 lit f DSGVO).</li>
        </ul>
        <h2 class="mb-2 font-bold mt-8 text-lg">6. Übermittlung Ihrer personenbezogenen Daten</h2>
        <p>
          Zu den oben genannten Zwecken werden wir Ihre personenbezogenen Daten an folgende Empfänger übermitteln:
        </p>
        <ul>
          <li>von uns eingesetzte IT-Dienstleister</li>
          <li>Hosting Provider</li>
          <li>Mapbox Inc.</li>
        </ul>
        <h2 class="mb-2 font-bold mt-8 text-lg">7. Dauer der Speicherung</h2>
        <p>
          Wir werden Ihre Daten, die durch den Besuch dieser Website von uns verarbeitet werden, grundsätzlich für eine Dauer von drei Monaten speichern. Eine längere Speicherung erfolgt nur, soweit dies erforderlich ist, um festgestellte Angriffe auf unsere Website zu untersuchen.
        </p>
        <p>
          Wenn Sie Ihre personenbezogenen Daten durch das Ausfüllen des Kontaktformulars auf unserer Website, an uns übermittelt haben, werden wir Ihre Daten jedenfalls so lange speichern, als dies für die Erledigung Ihrer Anfrage geboten erscheint und für gesetzliche Aufbewahrungsfristen und/oder Verjährungsfristen erforderlich ist.
        </p>
        <h2 class="mb-2 font-bold mt-8 text-lg">8. Ihre Rechte</h2>
        <p>
          Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung und Datenübertragbarkeit zu. Sofern Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt, können Sie sich bei der Datenschutzbehörde (<a href="https://www.dsb.gv.at/" rel="nofollow" class=" text-blue-500">https://www.dsb.gv.at/</a>), Wickenburggasse 8, 1080 Wien, <a href="mailto:dsb@dsb.gv.at" class=" text-blue-500">dsb@dsb.gv.at</a> beschweren.
        </p>
        <p>
          Wenn Sie Fragen im Zusammenhang mit dieser Datenschutzerklärung haben oder Ihre darin aufgeführten Rechte in Anspruch nehmen möchten, wenden Sie sich bitte per E-Mail an <a href="mailto:familienkarte@ktn.gv.at" class=" text-blue-500">familienkarte@ktn.gv.at</a>.
        </p>
        <h2 class="mb-2 font-bold mt-8 text-lg">9. Haftungsausschluss</h2>
        <p>
          Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Website verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.
        </p>
        <h3 class="mb-2 font-bold mt-8">9.1. Haftung für Inhalte dieser Webseite</h3>
        <p>
          Die Inhalte dieser Webseite wurden mit größtmöglicher Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte übernehmen wir jedoch keine Gewähr. Als Diensteanbieter sind wir für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Wir sind jedoch nicht verpflichtet, übermittelte oder gespeicherte, fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
        </p>
        <h3 class="mb-2 font-bold mt-8">9.2. Haftung für Links auf Websites Dritter</h3>
        <p>
          Unser Angebot enthält Links zu externen Websites. Auf den Inhalt dieser externen Websites haben wir keinerlei Einfluss. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        </p>
        <h3 class="mb-2 font-bold mt-8">9.3. Urheberrecht</h3>
        <p>
          Die Betreiber dieser Webseite sind bemüht, stets die Urheberrechte anderer zu beachten bzw. auf selbst erstellte sowie lizenzfreie Werke zurückzugreifen. Die durch die Seitenbetreiber erstellten Inhalte und Werke auf dieser Website unterliegen dem Urheberrecht. Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
        </p>

      </div>
    </div>
  </div>
</template>

<script>
import BackNav from "@/components/BackNav.vue";
export default {
  name: "Impressum",
  components: {
    BackNav
  },
  data() {
    return {
      viewName: "Impressum"
    };
  },
  computed: {
    acceptedMapboxTerms() {
      return this.$store.state.acceptedMapboxTerms
    },
  },
  methods: {
    removeMapbox() {
      this.$store.state.acceptedMapboxTerms = false
    }
  },
};
</script>

<style></style>
