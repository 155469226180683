<template>
  <div class="bg-gray-400">
    <div id="backNav" class="sticky top-0 z-50"><BackNav :viewName="viewName" :backwardsRoute="'home'" /></div>
    <div id="header" class="bg-xYellow flex content-center justify-center py-3 sm:py-12 shadow">
      <p class="text-white text-4xl">Goodies</p>
    </div>

    <div class="bg-gray-400">
      <GoodieFilter />
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
      <GoodieCard v-for="goodie in filteredGoodies" :key="goodie.nid" :goodie="goodie" />
    </div>
  </div>
</template>

<script>
import BackNav from "@/components/BackNav.vue";
import GoodieCard from "@/components/GoodieCard.vue";
import GoodieFilter from "@/components/GoodieFilter.vue";

export default {
  name: "GoodieClub",
  components: {
    BackNav,
    GoodieCard,
    GoodieFilter
  },
  data() {
    return {
      viewName: "Goodie Club"
    };
  },
  computed: {
    filteredGoodies: function() {
      // let filteredGoodies = [{"title":"B"},{"title":"A"},{"title":"C"}] // Test if sort function works
      let filteredGoodies = this.$store.getters.getFilteredGoodies;
      let sortedGoodies = filteredGoodies.sort((a, b) => {
        // check if "title" properties exist
        if (a.title && b.title) {
          if (a.title < b.title) {
            return -1;
          }
          if (a.title > b.title) {
            return 1;
          }
          return 0;
        }
        // if property doesn't exists order items at last
        return 1;
      });
      console.log("Goodies sorted according to their title.");
      return sortedGoodies;
    }
  },
  mounted() {
    this.$store.commit("SET_SELECTED_GOODIE", null);
    this.$store.commit("SET_SELECTED_LOCATION", null);
  }
};
</script>

<style></style>
