import Vue from 'vue'
import VueRouter from 'vue-router'
/* import store from '../store/store' */
import Home from '../views/Home.vue'
import GoodieClub from '../views/GoodieClub.vue'
import RundUmDieFamilie from '../views/RundUmDieFamilie.vue'
import News from '../views/News.vue'
import Jugendkarte from '../views/Jugendkarte.vue'
import Impressum from '../views/Impressum.vue'
import Newsletter from '../views/Newsletter.vue'
/* import MyCard from '../views/MyCard.vue' */
/* import MyCardRegistration from '../views/MyCardRegistration.vue' */
import SingleGoodie from '../views/SingleGoodie.vue'
import Map from '../views/Map.vue'
import legalNotes from '../views/LegalNotes.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: {name: 'home'},
  },
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/goodie-club',
    name: 'goodieClub',
    component: GoodieClub,
    children: [],
  },
  {
    path: '/goodie-club/karte',
    name: 'map',
    component: Map,
  },
  {
    path: '/goodie/:id',
    name: 'singleGoodie',
    component: SingleGoodie,
  },
  {
    path: '/rund-um-die-familie',
    name: 'rund-um-die-familie',
    component: RundUmDieFamilie,
  },
  {
    path: '/news',
    name: 'news',
    component: News,
  },
  {
    path: '/jugendkarte',
    name: 'jugendkarte',
    component: Jugendkarte,
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: Impressum,
  },
  {
    path: '/newsletter',
    name: 'newsletter',
    component: Newsletter,
  },
  {
    path: '/meine-karte',
    name: 'my-card',
    redirect: () => {
      return 'home'
    },
    /* component: MyCard,
    beforeEnter(to, from, next) {
      console.log(store.state.card)
      console.log('store.state.card empty?', Object.keys(store.state.card).length === 0)
      if (!(Object.keys(store.state.card).length === 0)) {
        next()
      } else {
        next({name: 'my-card-register'})
      }
    }, */
  },
  {
    path: '/meine-karte/anmeldung',
    name: 'my-card-register',
    /* component: MyCardRegistration */
    redirect: () => {
      return 'home'
    },
  },
  {
    path: '/datenschutzerklaerung',
    name: 'legal-notes',
    component: legalNotes,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
