<template>
  <div class="absolute bottom-2 w-full flex justify-center">
    <div class="bg-white w-2/3 max-w-md shadow-2xl p-1 relative flex flex-col sm:flex-row rounded">
      <div id="img-container" class="flex justify-center px-2 sm:w-24 w-full items-center">
        <img class="object-contain w-24 my-4" :src="selectedGoodie.logo" :alt="selectedGoodie.title + ' Logo'" />
      </div>
      <div class="flex-1">
        <p class="font-bold ml-2 my-1">{{ selectedGoodie.locations[parseInt(this.selectedLocation.split('_')[1])].name }}</p>
        <div class="m-2">
          <p class="">{{ selectedGoodie.locations[parseInt(this.selectedLocation.split('_')[1])].street }}</p>
          <p class="">{{ selectedGoodie.locations[parseInt(this.selectedLocation.split('_')[1])].zip }} {{ selectedGoodie.locations[parseInt(this.selectedLocation.split('_')[1])].city }}</p>
        </div>
        <!-- <div v-on:click="setSelectedGoodie">
          <router-link :to="{ name: 'singleGoodie' , params: {id: selectedGoodie.nid}}" >
            <p class="text-center text-blue-500 italic p-3">Zu den Vorteilen</p>
          </router-link>
        </div> -->
        <div class="sm:flex justify-around py-2">
          <div class="px-2">
            <button class="bg-xYellow w-full px-4 my-2 py-2 rounded shadow-lg" v-on:click="setSelectedGoodie">
              Zu den Vorteilen
            </button>
          </div>
          <div class="px-2">
            <button class="bg-xYellow w-full px-4 my-2 py-2 rounded shadow-lg" v-on:click="googleMaps">
              Navigation
            </button>
          </div>
        </div>
      </div>
      <div class="absolute top-0 right-0 transform -translate-y-3 transform translate-x-3" v-on:click="unSetSelectedGoodie">
        <button class="bg-xYellow rounded-full h-6 w-6 outline-none flex items-center justify-center shadow-xl" v-on:click="$emit('deselectHighlightedMarker')" >
          <font-awesome-icon
            icon="times"
            size="xs"
            :style="{ color: 'black' }"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    selectedGoodie() {
      return this.getSelectedGoodie(parseInt(this.selectedLocation.split('_')[0]))
    }
  },
  props: {
    selectedLocation: {
      type: String,
      default: null
    }
  },
  methods: {
    getSelectedGoodie(nid) {
      return this.$store.getters.getGoodieByNID(nid)
    },
    setSelectedGoodie() {
      this.$store.commit("SET_SELECTED_GOODIE", this.selectedGoodie);
      this.$store.commit("SET_SELECTED_LOCATION", null);
      this.$router.push({ name: 'singleGoodie' , params: {id: this.selectedGoodie.nid}});
    },
    unSetSelectedGoodie() {
      this.$store.commit("SET_SELECTED_GOODIE", null);
      this.$store.commit("SET_SELECTED_LOCATION", null);
    },
    googleMaps() {
      window.open('https://www.google.com/maps/dir/?api=1&destination=' + this.selectedGoodie.locations[parseInt(this.selectedLocation.split('_')[1])].lat + ',' + this.selectedGoodie.locations[parseInt(this.selectedLocation.split('_')[1])].lon);
    }
  }
};
</script>

<style></style>
